// export const sendgridConfig = {
//   env: "Production", // "Production" | "Staging"
//   production: {
//     contactThankYouTemplateId: "d-32b350e818e64136afdb34cceb161120",
//     contactDetailTemplateId: "d-4b7c9a5de74c455db572a5c90300eea5",
//     subscribedThankYouTemplateId: "d-8118c8ed3281427eb5d7fb1b93d2f8d0",
//     senderEmailId: "sales@nuflights.com",
//   },
//   staging: {
//     contactThankYouTemplateId: "d-e904e73ddfcb420f959cef2c4f6d36d9",
//     contactDetailTemplateId: "d-4604112c4eba4c87b0823d9d1eb70242",
//     subscribedThankYouTemplateId: "d-90a0af78a7e248eaaf6b3b84bdbcaf16",
//     senderEmailId: "vinod@tarkashilpa.com",
//   },
// };
export const hubspotSubscriptionType = {
  "sales-contact": "183430807",
  "product-news": "183430761",
  "partnership-news": "183430676",
  "technology-news": "183430589",
  "marketting-news": "179703932",
};
