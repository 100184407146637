import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { isEmpty } from "../utils/utils";

import { Amplify, API } from "aws-amplify";
import awsExports from "./../aws-exports";
import { hubspotSubscriptionType } from "../utils/contact-newsletter-config";
Amplify.configure(awsExports);

const SubscribeNewsletter = ({ contentAlign }: any) => {
  const [showLoader, setShowLoader] = useState(false);

  const subscribeValidationSchema = Yup.object().shape({
    EMAIL: Yup.string().email("Invalid email").required("Required"),
  });
  var [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [productNewsValue, setProductNewsValue] = useState("");
  const [partnershipNewsValue, setPartnershipNewsValue] = useState("");
  const [technologyNewsValue, setTechnologyNewsValue] = useState("");
  const [interestError, setInterestError] = useState("Required");
  const [error, setError] = useState<any>();

  const clearCheckBox = () => {
    setProductNewsValue("");
    setPartnershipNewsValue("");
    setTechnologyNewsValue("");
    document.getElementById("mce-group[80494]-80494-0").checked = false;
    document.getElementById("mce-group[80494]-80494-1").checked = false;
    document.getElementById("mce-group[80494]-80494-2").checked = false;
  };

  const apiHandler = async (values, resetForm) => {
    const subscriptionTypes: any = [];
    if (!isEmpty(productNewsValue)) {
      subscriptionTypes.push(hubspotSubscriptionType[productNewsValue]);
    }
    if (!isEmpty(partnershipNewsValue)) {
      subscriptionTypes.push(hubspotSubscriptionType[partnershipNewsValue]);
    }
    if (!isEmpty(technologyNewsValue)) {
      subscriptionTypes.push(hubspotSubscriptionType[technologyNewsValue]);
    }
    setShowLoader(true);

    API.post("mailjet", "/mailjet/hubspot/contact", {
      body: {
        type: "Newsletter",
        subscribeFor: subscriptionTypes,
        properties: {
          email: values.EMAIL,
        },
      },
    })
      .then((res) => {
        console.log("contactResponse", res);
        if (res.status === 200) {
          setIsValid(true);
          resetForm({});
          clearCheckBox();
          setTimeout(() => {
            setIsValid(false);
          }, 10000);
          setShowLoader(false);
        } else {
          console.log("Something went wrong");
          setError(res?.message);
          resetForm({});
          clearCheckBox();
          setTimeout(() => {
            setError("");
          }, 10000);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        setError("Something went wrong");
        resetForm({});
        clearCheckBox();
        setTimeout(() => {
          setError("");
        }, 10000);
        setShowLoader(false);
        console.log("contactError", error?.message);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          EMAIL: "",
        }}
        validationSchema={subscribeValidationSchema}
        onSubmit={(values, { setStatus, resetForm }) => {
          if (
            !isEmpty(productNewsValue) ||
            !isEmpty(partnershipNewsValue) ||
            !isEmpty(technologyNewsValue)
          ) {
            apiHandler(values, resetForm);
            setInterestError("");
            // setTimeout(() => {
            setIsValid(false);
            setError("");
            //   document
            //     .getElementById("mc-embedded-subscribe-form1")
            //     .setAttribute(
            //       "action",
            //       "https://nuflights.us1.list-manage.com/subscribe/post?u=a80ff68362ab1e09f8e602694&amp;id=291a55c460"
            //     );

            //   document.getElementById("mc-embedded-subscribe-form1").submit();
            // resetForm({});

            // }, 3000);
          } else {
            setInterestError("Required");
            setIsValid(false);
          }
        }}
      >
        {({ errors, touched }) => (
          <div className="subscribe-newsletter" id="contact">
            <div className="container1">
              <Form
                id="mc-embedded-subscribe-form1"
                name="mc-embedded-subscribe-form1"
                action="javascript:void(0)"
                method="post"
                target="_self"
                className="validate"
              >
                <div className="row contact-form mt-0">
                  <div id="mc_embed_signup" style={{ width: "100%" }}>
                    <div id="mc_embed_signup_scroll">
                      <div
                        className={`row justify-content-${
                          contentAlign === "left"
                            ? "start"
                            : contentAlign === "right"
                            ? "end"
                            : contentAlign
                        }`}
                      >
                        <div className="col-md-12 col-lg-2 col-xl-2 mb-3">
                          <span className="sub-text">Follow Us</span>
                        </div>
                        <div className="col-md-9 col-lg-7 col-xl-6 mb-3">
                          <div className="form-group mb-1">
                            <div className="mc-field-group">
                              <Field
                                name="EMAIL"
                                className="email formInput form-control"
                                id="mce-EMAIL"
                                placeholder="Enter email address"
                              />
                              {errors.EMAIL && touched.EMAIL ? (
                                <label className="error" id="mce-EMAIL-error">
                                  {errors.EMAIL}
                                </label>
                              ) : null}
                            </div>
                          </div>

                          <div className="mc-field-group">
                            <ul className="list-unstyled d-flex interested-in">
                              <li>
                                <input
                                  type="checkbox"
                                  value="product-news"
                                  name="group[80494][1]"
                                  id="mce-group[80494]-80494-0"
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      setProductNewsValue(event.target.value);
                                    } else {
                                      setProductNewsValue("");
                                    }
                                    setIsValid(undefined);
                                    setError("");
                                  }}
                                />
                                <label
                                  className="pl-2"
                                  htmlFor="mce-group[80494]-80494-0"
                                >
                                  Products News
                                </label>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  value="partnership-news"
                                  name="group[80494][2]"
                                  id="mce-group[80494]-80494-1"
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      setPartnershipNewsValue(
                                        event.target.value
                                      );
                                    } else {
                                      setPartnershipNewsValue("");
                                    }
                                    setIsValid(undefined);
                                    setError("");
                                  }}
                                />
                                <label htmlFor="mce-group[80494]-80494-1">
                                  Partnership News
                                </label>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  value="technology-news"
                                  name="group[80494][4]"
                                  id="mce-group[80494]-80494-2"
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      setTechnologyNewsValue(
                                        event.target.value
                                      );
                                    } else {
                                      setTechnologyNewsValue("");
                                    }
                                    setIsValid(undefined);
                                    setError("");
                                  }}
                                />
                                <label htmlFor="mce-group[80494]-80494-2">
                                  Technology News
                                </label>
                              </li>
                            </ul>
                            {isValid !== undefined && (
                              <div className="error">{interestError}</div>
                            )}
                            {error ? (
                              <div
                                style={{
                                  padding: "10px 0",
                                  color: "red",
                                }}
                              >
                                {error}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-3 col-lg-2 col-xl-2">
                          <div className="form-group">
                            <div id="mce-responses" className="clear">
                              <div
                                className="response"
                                id="mce-error-response"
                                style={{ display: "none" }}
                              ></div>
                              <div
                                className="response"
                                id="mce-success-response"
                                style={{ display: "none" }}
                              ></div>
                            </div>

                            <div
                              style={{
                                position: "absolute",
                                left: "-5000px",
                              }}
                              aria-hidden="true"
                            >
                              <input
                                type="text"
                                name="b_a80ff68362ab1e09f8e602694_291a55c460"
                                tabIndex={-1}
                                value=""
                                onChange={(event) => {}}
                              />
                            </div>
                            <div className="clear">
                              <button
                                type="submit"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                className="btn btn-outline-secondary mt-0 subscribe"
                              >
                                Subscribe
                              </button>
                              {showLoader && (
                                <div
                                  className="spinner-border text-primary position-relative top-10 left-10"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              )}
                            </div>
                            {isValid ? (
                              <div
                                className="success-message"
                                style={{
                                  padding: "10px 0",
                                  color: "green",
                                }}
                              >
                                Thank you for subscribe.
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SubscribeNewsletter;
